



















































import { Component, Vue } from "vue-property-decorator";
import SublimeOfficeDTO from "@/dto/sublime/office/SublimeOfficeDTO";
import RouteNames from "@/router/RouteNames";
import AdminService from "@/services/AdminService";
import { namespace } from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import SublimeOfficeCreationModal from "@/components/admin/office/SublimeOfficeCreationModal.vue";
import AddressDTO from "@/dto/AddressDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class SublimeOfficeList extends Vue {
    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private message = "";

    private successful = false;

    private RouteNames = RouteNames;

    private officeList: Array<SublimeOfficeDTO> = [];

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    created() {
        // Todo: Расскоментировать, когда поправим права на фронте
        // if (!this.hasAdministrativePrivileges) {
        //     this.$router.push({name: RouteNames.HOME});
        // }
    }

    mounted() {
        this.fetchOffices()
    }

    private convertAddressToString(address: AddressDTO) {
        return address.address1 + " " + address.city + " " + address.state + " " + address.zipCode;
    }

    private fetchOffices(): void {
        this.startLoading();
        AdminService.getAllOffices().then(
            res => this.processResponse(res.data),
            err => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                this.successful = false;
                this.stopLoading();
            }
        );
    }

    createOffice() {
        this.$modal.show(
            SublimeOfficeCreationModal,
            {
                onSuccess: () => this.fetchOffices()
            },
            {
                width: "50%",
                height: "auto",
                scrollable: true,
            }
        );
    }


    processResponse(value: Array<SublimeOfficeDTO>): void {
        this.officeList = value;
        this.stopLoading();
    }


};
